.react-calendar {
  font-family: $font-main !important;
  border: none;
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation {
    margin-bottom: 0;
  }
  .react-calendar__navigation__label {
    order: -1;
    flex-grow: 0 !important;
    margin-right: auto;
    font-weight: 600;
    letter-spacing: -0.5px;
    transition: all 0.2s ease;
    font-size: 15px;
    background-color: transparent !important;
    &.prevent-click {
      pointer-events: none;
    }
    @include hover {
      opacity: 0.8;
    }
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    cursor: text;
    text-decoration: none;
    color: $orange;
    font-weight: 400;
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    position: relative;
    font-size: 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url("../../images/arrow-right-dark.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 16px;
    }
  }
  .react-calendar__navigation__prev-button {
    &::after {
      transform: rotate(180deg);
    }
  }
  .react-calendar__navigation__arrow {
    min-width: 30px;
    height: 32px;
    position: relative;
    top: 5px;
    border-radius: 3px;
    transition: all 0.2s ease;
  }
  .react-calendar__tile {
    abbr {
      display: block;
      transition: all 0.2s ease;
      font-feature-settings: normal;
      letter-spacing: 0.7px;
    }
    &--now {
      background-color: transparent;
      abbr {
        background-color: $orange;
        color: $white;
        font-weight: 600;
      }
    }
    &:hover {
      background-color: transparent;
    }
    &:focus,
    &--active {
      background-color: transparent;
      abbr {
        background-color: $light-orange;
        color: $orange;
      }
    }
    @include hover {
      abbr {
        background-color: $light-orange;
        color: $orange;
      }
    }
  }
  .react-calendar__month-view {
    .react-calendar__tile {
      font-size: 13px;
      font-weight: 500;
      padding: 0;
      letter-spacing: -2px;
      margin: 1px;
      flex: 0 0 13.27% !important;
      abbr {
        padding: 5px 0;
        border-radius: 100%;
      }
    }
  }
  .react-calendar__year-view__months__month {
    padding: 0 2px;
    abbr {
      padding: 6px 3px;
      border-radius: 3px;
    }
  }
  .react-calendar__month-view__days__day {
    .react-calendar__tile {
      padding: 8px;
    }
  }
}
