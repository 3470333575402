.popup {
  position: fixed;
  z-index: 500;
  &--timesheet,
  &--day-detail {
    display: none;
  }
  &--timesheet {
    h2 {
      margin-bottom: 25px;
    }
    label {
      display: none;
    }
  }
  &.visible {
    display: block;
  }
  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(35, 40, 33, 0.3);
    z-index: 199;
  }
  h2 {
    font-weight: 600;
    letter-spacing: -0.8px;
  }
  %popup {
    position: fixed;
    z-index: 300;
    overflow: auto;
    background-color: $white;
  }
  &-inner {
    top: 0;
    bottom: 0;
    right: 0;
    width: 80%;
    max-width: 1466px;
    padding: 80px 50px 60px 80px;
    @extend %popup;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    span {
      font-weight: 600;
      letter-spacing: -0.8px;
    }
  }
  &-details {
    @extend %popup;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 80vh;
    width: 450px;
    padding: 60px 60px 50px;
    border-radius: 7px;
    .popup__close {
      left: auto;
      right: 25px;
    }
    h2 {
      margin-bottom: 0;
    }
  }
  &__close {
    position: absolute;
    left: 30px;
    top: 25px;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.2s ease;
    img {
      width: 15px;
    }
    @include hover {
      opacity: 0.7;
    }
  }
}

.form-buttons-wrap {
  text-align: right;
  button {
    margin-left: 20px;
  }
}

.form-row {
  display: flex;
  align-items: center;
}
