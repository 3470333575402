.rbc-time-view {
  border: none;
  .rbc-label {
    display: none;
  }
  .rbc-allday-cell {
    height: calc(100vh - 98px);
    max-height: unset;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }
  .rbc-time-header-content,
  .rbc-header {
    border: none;
  }
  .rbc-time-content {
    display: none;
  }
  .rbc-time-header-cell {
    border-bottom: 1px solid $border-color;
  }
}

.rbc-toolbar {
  position: relative;
  margin-bottom: 30px;
  .rbc-btn-group {
    &:first-child {
      button {
        &:first-child {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $orange;
          border: 1px solid $orange;
          padding: 6px 16px;
          text-align: center;
          transition: 0.2s ease;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 700;
          @include hover {
            background-color: $orange;
            color: $white;
          }
        }
      }
    }
  }
  .rbc-toolbar-label {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin-left: 15px;
  }
}

.rbc-btn-group {
  &:first-child {
    button {
      &:nth-child(2),
      &:last-child {
        position: relative;
        background-color: $orange;
        font-size: 0;
        width: 36px;
        height: 39px;
        border-radius: 4px;
        border: none;
        transition: opacity 0.2s ease;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: url("../../images/arrow-right.svg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 16px;
        }
        @include hover {
          opacity: 0.8;
        }
      }
      &:nth-child(2) {
        margin-right: 10px;
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
  &:last-child {
    border: 1px solid $grey-lightish;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    button {
      background-color: transparent;
      color: $dark;
      border: none;
      padding: 5px 10px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none;
      transition: all 0.2s ease;
      &.rbc-active {
        background-color: $light-orange;
        color: $orange;
      }
      @include hover {
        background-color: $light-orange;
        color: $orange;
      }
    }
  }
}

.rbc-today {
  background-color: $white;
}

.rbc-date-cell a {
  font-size: 11px;
  font-weight: 700;
}

.month__day-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.month__day-title {
  border-radius: 4px;
  font-weight: 600;
  padding: 3px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $dark-text;
  margin-right: 6px;
}

.month__day-cta {
  position: relative;
  padding-left: 20px;
  transition: color 0.2s ease;
  &::before {
    content: "+";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $orange;
    color: $white;
    width: 9px;
    height: 9px;
    left: 8px;
    position: absolute;
    bottom: 1px;
    border-radius: 100%;
  }
  @include hover {
    color: $orange;
  }
}

.month__day-header {
  position: relative;
  margin-bottom: 1px;
  margin-top: 2px;
}

.month__day-footer {
  position: absolute;
  bottom: -114px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  color: $grey-lightish;
  transition: 0.25s ease;
}

.show-more {
  position: absolute;
  bottom: -14px;
  display: flex;
  width: 28px;
  padding: 3px 5px;
  align-items: center;
  justify-content: space-between;
  background-color: $border-color;
  border-radius: 5px;
  cursor: pointer;
  span {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #636363;
  }
}

.rbc-day-bg {
  border-color: $border-color !important;
}

.rbc-event {
  background-color: transparent !important;
}

.rbc-month-view {
  border: none;
  border-bottom: 1px solid $border-color;
  .rbc-row-content {
    position: relative;
    .rbc-row {
      &:nth-child(4):last-child {
        .show-more {
          display: none;
        }
      }
      &:nth-child(n + 5) {
        display: none;
      }
      &:not(:nth-child(4)) {
        position: relative;
        .show-more {
          display: none;
        }
      }
    }
  }
  .rbc-event {
    outline: none !important;
    color: $dark-text;
    font-size: 11px;
    padding: 1px 8px;
    cursor: text;
    span {
      display: block;
      line-height: 1.1;
    }
  }
  .rbc-date-cell {
    padding: 0 11px 0 2px;
    &.rbc-now {
      .month__day-date {
        color: $white;
        &::before {
          opacity: 1;
          background-color: $orange;
        }
      }
    }
  }
  .month__day-date {
    position: relative;
    font-size: 12px;
    letter-spacing: -1.5px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 22px;
    height: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: -8px;
    &::before {
      content: "";
      opacity: 0;
      width: 22px;
      height: 22px;
      background-color: $light-orange;
      border-radius: 100%;
      display: block;
      position: absolute;
      right: -1px;
      transition: opacity 0.2s ease;
      z-index: -1;
    }
    @include hover {
      color: $orange;
      &::before {
        opacity: 1;
      }
    }
  }
  .month__day-hours {
    color: #636363;
    font-size: 14px;
    letter-spacing: -1.5px;
    pointer-events: none;
    line-height: 0;
  }
  .rbc-off-range-bg {
    background-color: #f5f5f5;
  }
  .rbc-now.rbc-current {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 100%;
      background-color: $orange;
      color: $white;
      width: 22px;
      height: 22px;
      font-size: 11px;
      margin-top: 4px;
    }
  }
}

.rbc-month-row {
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-color: $border-color !important;
}

.rbc-month-header {
  .rbc-header {
    padding: 8px 0;
    font-weight: 700;
    font-size: 15px;
    color: rgba($black, 0.3);
    border-left: none;
    text-align: left;
    border-bottom-color: $border-color;
  }
}

.timesheet {
  padding: 70px 0;
  .row {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
  .timesheet-left {
    width: 229px;
    padding: 0 15px;
    margin-right: 20px;
  }
  .timesheet-right {
    width: calc(100% - 341px);
    padding: 0 15px;
    flex-grow: 1;
    overflow: hidden;
  }
  @include mq($lg) {
    .row {
      display: block;
    }
    .timesheet-right {
      width: 100%;
    }
  }
}

.event {
  &-group {
    margin-bottom: 10px;
  }
  &__client,
  &__project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  &__client {
    font-weight: 600;
    color: $dark-text;
    margin-bottom: 8px;
  }
  &__client-name {
    border: 1px solid;
    border-radius: 5px;
    padding: 3px 10px 1px;
  }
  &__project {
    font-weight: 400;
  }
  &__project-name {
    padding-left: 11px;
    color: $grey;
  }
  &__project-hours {
    color: $grey-mid;
  }
}
