/*--------------------------------------------------------------
## Base Layout
--------------------------------------------------------------*/
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  height: 100%;
  font-family: $font-main;
  &.stop-scroll {
    overflow: hidden;
  }
}

html {
  box-sizing: border-box;
  --scrollbarBG: #ffe1d8;
  --thumbBG: #e9572698;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  background-clip: text;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px;
  @include mq($lg) {
    max-width: 720px;
  }
}

body,
.popup-inner,
.popup-details {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 11px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}

/* Firefox Scrollbar */
:root {
  scrollbar-color: $orange $light-orange !important;
  scrollbar-width: thin !important;
}

.btn {
  display: inline-block;
  text-align: center;
  background-color: $orange;
  color: #fff;
  padding: 10px;
  outline: 0;
  min-width: 82px;
  transition: 0.35s ease;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 800;
  border: 0;
  &--transparent {
    background-color: transparent;
    color: $grey-mid;
  }
  @include hover {
    background-color: $dark;
    color: $white;
  }
}

.form-element {
  font-size: 14px;
  font-weight: 600;
  .ant-select-selector {
    border-radius: 4px;
  }
}
