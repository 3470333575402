/*--------------------------------------------------------------
# Reset and Variables
--------------------------------------------------------------*/

@import "./config/reset";
@import "base/fonts";
@import "./config/variables";

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/
@import "helpers/mixins";
@import "helpers/print";

/*--------------------------------------------------------------
# Libraries and Plugins
--------------------------------------------------------------*/

@import "~antd/dist/antd";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/grid-breakpoints";
@import "base/base";
@import "base/typography";
@import "base/antd";

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "components/popups";
@import "components/timesheet";
@import "components/mini-calendar";
