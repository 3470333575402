/*--------------------------------------------------------------
## Main Variables
--------------------------------------------------------------*/
// Fonts
$font-default-serif: Georgia, serif;
$font-default-sans-serif: Arial, Helvetica, sans-serif;

$font-main: "Montserrat", $font-default-sans-serif;

$site-fonts: (
  "font-main": $font-main
) !default;

// Colors
$white: #fff;
$whitish: #eee;
$black: #000;
$dark-text: #303030;
$dark: #232821;
$red: #d4461a;
$red-dark: #c73609;
$orange: #e95626;
$light-orange: #ffe1d8;
$green: #0f834d;
$blue: #3d9cd2;
$grey-light: #f1f1f1;
$grey-lightish: #b9b9b9;
$grey-mid: #838383;
$grey: #636363;
$grey-dark: #404040;
$border-color: #e9e9e9;

$site-colors: (
  "white": $white,
  "black": $black,
  "red": $red,
  "green": $green,
  "blue": $blue,
  "grey-light": $grey-light,
  "grey-mid": $grey-mid,
  "grey": $grey,
  "grey-dark": $grey-dark,
  "orange": $orange,
  "light-orange": $light-orange
) !default;

// Storybook colors
$sg-orange: #e95626;
$sg-blue: #71999e;
$sg-grey: #fafafa;

// Transitions
$dur: 350ms;
$ease: ease;

/*--------------------------------------------------------------
## Bootstrap Variables
--------------------------------------------------------------*/
$enable-grid-classes: true !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
  xxl: 1680px
) !default;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px,
  xxl: 1650px
) !default;

/*
	Breakpoints for max-width.

	These variables are mapped with $grid-breakpoints SCSS map variable which is used for bootstrap.
	The reason for this setup is that it is needed to have variables that reflect bootstrap's breakpoints.
	Bootstrap is using min-with and this starter max-with approach, so bootstrap's breakpoint at 992px (min-width)
	is reflected as 991px (max-width), thus the mapped variables bellow.
 */

$sm: map-get($grid-breakpoints, sm) - 1;
$md: map-get($grid-breakpoints, md) - 1;
$lg: map-get($grid-breakpoints, lg) - 1;
$xl: map-get($grid-breakpoints, xl) - 1;
$lap-small: 1250px;
$lap-large: 1400px;

// Grid columns
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
